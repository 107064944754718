@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

:root {
  --leftDesktopContentWidth: 640px;
}

.root {
}

.pageRoot {
  display: flex;
  padding-bottom: 90px;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.sectionImages {
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.sectionImagesWithSlider {
  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: 80px auto;
    grid-gap: 24px;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;

  @media (--viewportMedium) {
    padding: 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative; /* allow positioning own listing action bar */
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;

  @media (--viewportMedium) {
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }
}

.actionBar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.ownListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  @apply --marketplaceH4FontStyles;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: 458px;
    object-fit: cover;
    border-radius: var(--borderRadius);
  }
}

.viewPhotos {
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;
  z-index: 1;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentContainer {
  margin: 24px 0 48px;

  @media (--viewportMedium) {
    margin: 24px 0 76px;
  }
}

.mainContent {
  @media (--viewportLarge) {
    display: grid;
    grid-template-columns: var(--leftDesktopContentWidth) auto;
    grid-gap: 48px;
  }
}

.bookingPanel,
.productPanel {
  @media (--viewportLarge) {
    display: block;
    min-height: 458px;
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.sectionHeading {
  margin-top: 22px;
  margin-bottom: 34px;

  @media (--viewportMedium) {
    display: none;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH5FontStyles;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  & .separator {
    position: relative;
    top: 1px;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH5FontStyles;
  margin: 0;

  font-family: 'poppins', Helvetica, Arial, sans-serif;
}

.sectionDescription {
  padding: 0 24px;
  margin-top: 35px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 36px;
    margin-bottom: 36px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.descriptionTitle {
  /* Font */
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 13px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.sectionFeatures {
  padding: 0 24px;
  margin-top: 35px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 36px;
    margin-bottom: 52px;
  }
}

.featuresTitle {
  /* Font */
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 24px;
  }
}

.features {
  /* Layout */
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;

  margin: 0px;

  @media (--viewportMedium) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.feature {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.featureField {
  font-family: var(--titleFontFamily);
  font-weight: var(--fontWeightMedium);
  text-transform: capitalize;
  margin-right: 12px;
}

.featureValue {
  font-weight: var(--fontWeightRegular);
}

.featureSpacer {
  /* Span column 100% */
  grid-column: 1 / -1;

  /* Style */
  background: var(--matterColorNegative);
  height: 1px;
}

.description {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: var(--fontWeightRegular);

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-top: 35px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 36px;
    margin-bottom: 0px;
  }
}

.locationTitle {
  /* Font */
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 24px;
  }
}

.sectionReviews {
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.reviewsHeading {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColor);
  margin-top: 35px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 36px;
  }
}

.readAllReviews {
  margin-top: 24px;
}

.readAllReviewsText {
  /* Font */
  @apply --marketplaceBodyFontStyles;
  text-transform: uppercase;
  text-decoration: underline;
  margin: 0;
}

.sectionHost {
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0px;
  }
}

.yourHostHeading {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColor);

  margin-top: 35px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 36px;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.sectionListings {
  margin-top: 32px;

  @media (--viewportMedium) {
    /* Position and layout */
    margin-top: 48px;
    padding: 38px 0 0;
    border-top: 1px solid var(--matterColorNegative);
  }
}

.listingsTitle {
  /* Font */
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColor);
  margin: 0 0 24px;
  padding-left: 24px;

  @media (--viewportMedium) {
    text-align: center;
    padding-left: 0px;
  }
}

.desktopListingsSlider {
  display: none;

  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 12px;
    padding: 0 48px;
  }

  & .carouselCard {
    &:nth-child(3),
    &:nth-child(4) {
      display: none;
    }

    @media (--viewportMedium) {
      &:nth-child(3),
      &:nth-child(4) {
        display: block;
      }
    }
  }
}

.mobileListingsSlider {
  @media (--viewportMedium) {
    display: none;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(60vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: var(--leftDesktopContentWidth);
  max-height: var(--leftDesktopContentWidth);
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.breadcrumbsContainer {
  padding: 0 24px;
  margin: 0 0 24px;

  @media (--viewportMedium) {
    padding: 0px;
  }
}

.breadcrumbsList {
  /* Layout */
  display: inline;
  line-height: 1.5;

  /* Remove ul margin */
  margin: 0;

  @media (--viewportLarge) {
    max-width: var(--leftDesktopContentWidth);
  }
}

.crumb,
.crumbLink {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  font-weight: var(--fontWeightMedium);
  text-transform: capitalize;

  /* Remove margins */
  margin: 0;
}

.crumb {
  display: inline;

  &:before {
    content: '/';
    margin: 0 8px;
  }

  &:first-child {
    &:before {
      display: none;
    }
  }
}

.crumbLink {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  font-weight: var(--fontWeightMedium);

  /* Animation */
  transition: all var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColor);
  }
}

.imageSlider {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.noSliderContainer {
  & .imageSliderItemContainer {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.noSliderItem {
  opacity: 0.6;
  cursor: pointer;
}

.noSliderItemActive {
  position: relative;
  opacity: 1;
  cursor: default;

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    border: 2px solid #191a1c;
    border-radius: 2px;
  }
}

.imageSliderItemContainer {
  width: 80px !important; /* Override react-slick */
  height: 80px;
}

.imageSliderItem {
  width: 100%;
  height: 100%;
  border-radius: var(--borderRadius);
}

.imageSliderArrow {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  cursor: pointer;
}

.imageSliderNextArrow {
  padding-top: 4px;
  padding-bottom: 0px;
}

.imageSliderArrowIcon {
  height: 16px;
  width: 16px;
  transition: all var(--transitionStyleButton);

  /* Color */
  stroke: none;
  fill: var(--matterColor);
}

.arrowDisabled {
  pointer-events: none;

  & .imageSliderArrowIcon {
    fill: #9f9f9f;
  }
}

.showMoreContainer {
  /* Font */
  @apply --marketplaceBodyFontStyles;
  color: var(--marketplaceColor);

  /* Animation */
  cursor: pointer;
  opacity: 0.8;
  transition: opacity var(--transitionButtonStyle);

  @media (--viewportMedium) {
    /* Layout */
    max-width: 60%;
  }

  &:hover {
    opacity: 1;
  }
}

.showMoreIcon {
  margin-left: 6px;

  /* Fill */
  stroke: var(--marketplaceColor);
  fill: var(--marketplaceColor);
}

.topContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.smallContactLink {
  /* Font */
  font-size: 13px;
  color: var(--marketplaceColor);

  display: none;
  @media (--viewportMedium) {
    display: block;
  }
}
